.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Launch-Text {
    /* animation: App-logo-animate 2s infinite; */
    animation: wiggle 4s ease-in-out infinite, glow 2s ease-in-out infinite alternate;
    color: #281205;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-animate 2s infinite; */
    animation: App-logo-animate 2s infinite;
  }
}

.App-header {
  background-color: rgb(247, 247, 237);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #ffdab9, 0 0 20px #ffdab9, 0 0 30px #ffdab9, 0 0 40px #ffdab9;
  }
  to {
    text-shadow: 0 0 10px #ffdab9, 0 0 20px #ffdab9, 0 0 30px #ffdab9, 0 0 40px #ffdab9, 0 0 50px #ffdab9, 0 0 60px #ffd700;
  }
}

@keyframes App-logo-animate {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

/* Keyframes */
@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  75% {
    transform: rotateZ(10deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}
